import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = []

const content = () => (
  <div>
    <br />

    <p>
      스테이지파이브(대표 서상원)는 안랩(대표 강석균)과의 협업을 통해 알뜰폰 개통 명의도용에 대한 방지 노력을 강화하기 위한 정기
      보안 점검을 이어가고 있다고 29일 밝혔다.
      <br />
      <br />
      최근 알뜰폰을 통한 개통 명의도용 사례가 증가하고 있어, 해당 서비스를 운영하는 기업들에도 이에 대한 선제적이고 적극적인
      대응이 요구되어지고 있다. 스테이지파이브는 ‘핀다이렉트’를 운영하고 있는 통신사업자로서, 고객의 개인정보 보호 권리를 보장하는
      등 사회적 책임을 다 하기 위해 안랩과 협력하여 정기 보안 점검을 강화하고 있다.
      <br />
      <br />
      해당 점검은 스테이지파이브가 구축한 핀다이렉트 개통시스템 및 서비스에 대한 취약점을 식별하고 보완하기 위해 진행되었다. 이번
      점검에서는 특히 알뜰폰 개통 과정에서 발생할 수 있는 보안 취약점을 중점적으로 살펴본 바 있다.
      <br />
      <br />
      스테이지파이브 보안담당자는 "알뜰폰을 통한 개통 명의도용은 심각한 사회적 문제로 인식되고 있다. 스테이지파이브는 이후에도
      보안업체들과 협업을 강화, 한층 업그레이드된 정기 보안 점검을 지속적으로 실시하고자 한다"며 "앞으로도 보다 안전하고 신뢰할 수
      있는 서비스를 제공하기 위해 최선을 다할 것"이라고 전했다.
      <br />
      <br />
    </p>
  </div>
)

const press52 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2024년 03월 29일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 알뜰폰 개통 명의도용 방지 노력 강화… 안랩과 함께 보안 점검"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press52
